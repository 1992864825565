import AuthService from "./auth.service";
import ApplicationService from "./application.service";
import SignupService from "./signup.service";
import EditService from "./store/edit.service";
import DepositService from "./store/deposit.service";
import InvoiceReceiptService from "./store/invoiceReceipt.service";
import AreaSettingService from "./adminStore/areaSetting.service";
import DepositAdminService from "./adminStore/deposit.service";
import PayGateService from "./adminStore/notification/paygate.service";
import AdAddressService from "./adAddress.service";
import ContactFormService from "./contactForm.service";
import DocumentDownloadService from "./documentDownload.service";
import InvoiceMonthlyService from "./invoiceMonthly.service";
import ReceiptMonthlyService from "./receiptMonthly.service";
import InvoiceService from "./invoice.service";
import ReceiptService from "./receipt.service";
import AdminInvoiceReceiptService from "./adminStore/invoiceReceipt.service";
import AdminInvoiceMonthlyService from "./adminStore/invoiceMonthly.service";
import AdminReceiptMonthlyService from "./adminStore/receiptMonthly.service";
import AgxFeeRateService from "./agxFeeRate.service";
import GMOService from "./gmo.service";
import CrepicoPaymentService from "./crepicoPayment.service";
import NoticeService from "./notice.service";

export const authService = new AuthService({});
export const applicationService = new ApplicationService({});
export const signupService = new SignupService({});
export const editService = new EditService({});
export const depositService = new DepositService({});
export const invoiceReceiptService = new InvoiceReceiptService({});
export const areaSettingService = new AreaSettingService({});
export const depositAdminService = new DepositAdminService({});
export const payGateService = new PayGateService({});
export const adAddressService = new AdAddressService({});
export const contactFormService = new ContactFormService({});
export const documentDownloadService = new DocumentDownloadService({});
export const invoiceMonthlyService = new InvoiceMonthlyService({});
export const receiptMonthlyService = new ReceiptMonthlyService({});
export const invoiceService = new InvoiceService({});
export const receiptService = new ReceiptService({});
export const adminInvoiceReceiptService = new AdminInvoiceReceiptService({});
export const adminInvoiceMonthlyService = new AdminInvoiceMonthlyService({});
export const adminReceiptMonthlyService = new AdminReceiptMonthlyService({});
export const agxFeeRateService = new AgxFeeRateService({});
export const gmoService = new GMOService({});
export const crepicoPaymentService = new CrepicoPaymentService({});
export const noticeService = new NoticeService({});
