const API_ENDPOINT = {
    AUTH: "auth",
    SIGNIN: "auth/signin",
    SIGNUP: "auth/signup",
    CHANGE_PASSWORD: "auth/change-password",
    CHANGE_INFO: "auth/change-info",
    CHECK_AUTH: "auth/check-auth",
    PROFILE: "account",
    MERCHANT: "agx-merchant/user",
    AGX_PAYMENT_BREAKDOWN: "agx-payment-breakdown",
    AGX_MERCHANT_PAYMENT: "agx-merchant-payment",
    AGX_PAYMENT_MANAGEMENT: "agx-payment-management",
    AGX_PAYMENT_DETAIL: "agx-payment-detail",
    AGX_INVOICE: "agx-invoice",
    AGX_INVOICE_DETAIL: "agx-invoice-detail",
    AGX_AREA: "agx-area",
    AGX_FEE_RATE: "agx-fee-rate",
    AGX_SUB_AREA: "agx-sub-area",
    AGX_CREPICO_PAYMENT_DATA: "agx-crepico-payment-data",
    ADMIN_DEPOSIT: "admin-deposit",
    ADMIN_DEPOSIT_DETAIL: "admin-deposit-detail",
    MAIL_REGISTER: "mail/register",
    DEPOSIT: "deposit",
    SUMMARY: "summary",
    SUMMARY_MONTHLY: "summary-monthly",
    NOTIFICATION_RULES: "notification-rules",
    AREA_SETTING: "area-setting",
    NOTIFICATION_PAYGATE: "notification/paygate",
    AD_ADDRESS: "ad-address",
    FIND_BY_DATA_STEP4: "https://apis.bankcode-jp.com/v1/banks/",
    AGX_DOCUMENT_DOWNLOAD: "agx-document-download",
    AGX_CONTACT_FORM: "agx-contact-form",
    INVOICE_RECEIPT: "invoice-receipt",
    NOTICE: "notice",
    ADMIN_INVOICE_RECEIPT:"admin-invoice-receipt",
    ADMIN_INVOICE_MONTHLY:"admin-invoice-monthly",
    ADMIN_RECEIPT_MONTHLY:"admin-receipt-monthly",
    CREDIT_CARD_MONTHLY_FEE:"credit-card-monthly-fee",
    MONTHLY_COST_BY_STORE:"monthly-cost-by-store",
    STORE_DEPOSIT_DETAIL:"store-deposit/store-deposit-detail",
    GMO_PAYMENT:"gmo-payment",
    GMO_MEMBER:"gmo-member"
  };
  
  export default API_ENDPOINT;
  